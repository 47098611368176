import { React, useState, useEffect } from "react";
import { Grid, Paper, Typography, Box, Button } from "@mui/material";
import axios, { post } from "axios";

export default function Uploader() {
  const [state, setState] = useState();
  const onFileChange = (e) => {
    setState({ file: e.target.files[0] });
  };

  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    document.getElementById("uploadButton").innerHTML = "Processing...";
    document.getElementById("uploadButton").disabled = true;
    fileUpload(state.file).then((response) => {
      console.log("GOT IT", response.data);
      document.getElementById("uploadButton").innerHTML = "Loading Images...";
      setTimeout(() => {
        console.log("I waited.");
        let path = response.data.randomName;
        document.getElementById("pageImages").innerHTML = "";
        response.data.pages.forEach((pageKey) => {
          getImage(pageKey);
        });
        document.getElementById("uploadButton").innerHTML = "Convert";
        document.getElementById("uploadButton").disabled = false;
      }, 2000);
      // show the image
    });
  };

  const getImage = (imgPath) => {
    axios
      .post("https://api-dev.vectordesk.io:3333/page_image", {
        key: imgPath,
      })
      .then((data) => {
        //                console.log(data.data);
        var a = document.createElement("a");
        a.href = data.data;
        a.target = "_blank";
        var img = document.createElement("img");
        img.src = data.data;
        img.style = "width:300px;border:1px solid #ccc;margin:10px;box-shadow:0px 3px 2px #ccc;";
        a.appendChild(img);
        document.getElementById("pageImages").appendChild(a);
      })
      .catch(() => {});
  };

  const fileUpload = (file) => {
    const url = "https://api-dev.vectordesk.io:3333/upload";
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return post(url, formData, config);
  };

  return (
    <div className="page wizardPage" style={{ padding: "15px 40px", width: "100hw !important" }}>
      <div id="page-head">
        <div id="page-title">
          <div className="row">
            <div className="col-md-9">
              <ol className="breadcrumb"></ol>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/vectorDESK_logo_1.png" style={{ height: "60px" }} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <h4>PDF to PNG Secure Conversion Tool</h4>
        </Grid>
      </Grid>

      <Paper className="formPanel" style={{ width: "100hw !important" }}>
        Browse for a PDF file below, then click Convert to convert it to PNG image(s).
        <form onSubmit={onFormSubmit}>
          <h1>File Upload</h1>

          <input type="file" onChange={onFileChange} />
          <br />
          <br />
          <Button variant="contained" type="submit" id="uploadButton" className="btn">
            Convert
          </Button>
        </form>
        <div id="pageImages"></div>
      </Paper>
    </div>
  );
}
