import { React, useState, useEffect } from "react";
import { Grid, Paper, Typography, Box, MenuItem, Menu, Avatar, Divider, ListItemIcon } from "@mui/material";

function Header() {
    return (
        <>
            <Box>
                <img src="/assets/img/vectorVOICE_logo_1.png" className="headerLogo" alt="logo" />
                <ul className="leftnavLinks">
                    <li>
                        <a href="/uploader">Uploader</a>
                    </li>
                </ul>
            </Box>
        </>
    );
}

export default Header;
