import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import Uploader from "./components/Uploader/Uploader";
import Header from "./components/Common/Header";
import { Grid } from "@mui/material";

const App = () => {
  return (
    <BrowserRouter>
      <Grid container spacing={1}>
        <Grid item id="mainColumn" style={{ width: "100% !important" }}>
          <Route path="/" component={Uploader} />
        </Grid>
      </Grid>
    </BrowserRouter>
  );
};

export default App;
